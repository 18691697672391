.link {
  cursor: pointer;
  line-height: 30px;
  background-position: 5px center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;

  + .link {
    margin-top: 16px;
  }

  &.active {
    color: #141625;
    font-weight: 500;

    &:before {
      content: "";
      position: absolute;
      left: -20px;
      top: -5px;
      width: 3px;
      height: 40px;
      background-color: #347dfe;
    }
    
    &[href="/cast"] {
      &:before {
        top: 5px;
      }
    }
  }

  .notificate {
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: #ffcc00;
    margin: 0 -.7em;
    border-radius: 50%;
    text-align: center;
    justify-self: flex-end;
    flex-shrink: 0;
  }


  svg {
    color: #a7b8e3;

    path {
      fill: currentColor;
    }
  }

  &.active svg {
    color: #347dfe;
  }

  .icon {
    display: flex;
    align-items: center;
    width: 30px;
  }

  .title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 30px;
  }

  &.shrinked {
    width: 60px;
    height: 54px;
    justify-content: center;
    margin: 0;

    &.active:before {
      left: 0;
      top: 6px;
    }

    .icon {
      width: auto;
    }
  }
}
