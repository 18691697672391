.CryptoModal {
  &_icon {
    height: 100px;
    width: 100px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    letter-spacing: 1px;
    background-color: #f1f5ff;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }
}
