@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");

$color-main: white;
$color-nonactive: #8c8f9e;
$green: #a1c229;
$deep-blue: #070c17;
$blue:#28488d;

$input-gradient: $deep-blue;

$header-height: 93px;
$footer-height: 100px;
$main-height: 70%;

$small-screen: 768px;
$medium-screen: 980px;
$large-screen: 1240px;
$wide-screen: 1920px;

// it will used if we will download pictures as sprites
$social-sprite:(
    url: '../img/social.png',
    names:('yt', 'vk', 'tg', 'ws'),
    columns: 4,
    rows: 1
);