.PaymentSystems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 600px) {
    margin: 20px auto !important;
    justify-content: center;
  }

  &-System {
    width: 280px;
    height: 122px;
    border-radius: 8px;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 20px;
    padding-left: 53px;
    position: relative;
    cursor: pointer;
  
    &:before {
      content: "";
      position: absolute;
      left: 14px;
      top: 13px;
      height: 10px;
      width: 26px;
      height: 26px;
      border: 1px solid #dce6ff;
      background-color: #f6f7fb;
      border-radius: 50%;
    }
  
    .subtitle {
      color: #a7b8e3;
      font-size: 13px;
      font-weight: 400;
    }
  
    &:active,
    &_selected {
      box-shadow: 0 19px 27px rgba(52, 125, 254, 0.2);
  
      &:before {
        background-color: #347dfe;
      }
    }
  }

  &-PaymentSystemImgWrapper {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-Title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  &-Balance {
    background-image: url("./img/coin.svg");
    background-repeat: no-repeat;
    background-position: right center;
  }

  &-UserBalance {
    margin-top: 26px;
    font-size: 20px;
    font-weight: 500;
  }

  @media (max-width: 600px) {
    margin: 20px auto !important;
  }
}
