.footer {
  padding: 0 20px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #cbcfe2;
  font-size: 13px;
  line-height: 19px;

  .socials {
    display: flex;
    margin-right: 20px;
    
    > * {
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;

      /* + * {
        margin-left: 10px;
      } */
    }

    /* .yt {
      background-image: url('../../img/socials/icon_yt.svg');
    } */

    /* .vk {
      background-image: url('../../img/socials/icon_vk.svg');
    } */

    .tg {
      background-image: url('../../img/socials/icon_tg.svg');
    }

    .ig {
      background-image: url('../../img/socials/icon_ig.svg');
    }

    .wa {
      background-image: url('../../img/socials/icon_wa.svg');
    }
  }

  .contactsAndTrademark {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .contacts {
      display: flex;
      flex-direction: column;
    }
  }
  
}

