.sidebar {
  position: relative;
  background-color: #dce6ff;
  padding: 24px 20px;
  min-height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  font-size: 15px;
  color: #545769;
  transition: all .2s;

  @media (max-width: 850px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 888;
    box-shadow: 1px 1px 15px 6px rgba(0, 0, 0, 0.1);
  }

  &.shrinked {
    padding: 34px 0 30px;
    width: 60px;
    transition: all .2s;

    @media (max-width: 850px) {
      left: -100%;
    }
  }

  .hamburgerAndLogo {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .hamburger {
      height: 30px;
      width: 30px;
      background-image: url('../../img/hamburger.svg');
      background-repeat: no-repeat;
      background-position: left center;
      cursor: pointer;
      flex-shrink: 0;
    }

    .logo {
      height: 50px;
      width: 50px;
      background-image: url('../../img/logo.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 10px;
      margin-top: 8px;
    }

    .title {
      :global .mainTitle {
        text-align: center;
        font-size: 36px;
        font-weight: 200;
        margin-top: 0;
        margin-bottom: 0;
        color: #308efa;
      }

      .mainSubTitle {
        font-size: 13px;
        font-weight: 400;

        > span {
          font-weight: 700;
        }
      }
    }
  }

  &.shrinked {
    .hamburgerAndLogo {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 10px;

      .hamburger {
        width: 19px;
      }

      .logo {
        margin: 0;
        margin-top: 25px;
        height: 35px;
      }
    }
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    opacity: .5;
    height: 100vh;
    width: 10px;
    cursor: col-resize;
    user-select: none;

    @media (max-width: 850px) {
      display: none;
    }
  }
  .active {
    left: 0;
    width: 100vw;
    z-index: 1000;
  }
}
