button {
  border: 0;
}

.wrapper {
  display: flex;
  min-width: 320px;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  flex-grow: 1;

  > :nth-child(2) {
    padding: 20px;
    padding-bottom: 0;
    flex-grow: 1;
  }
}

.loginRegBg {
  background-image: url('img/login_reg_bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

/* Повторяющийся заголовок для многих страниц */
:global .mainTitle {
  font-size: 17px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 23px;
}
