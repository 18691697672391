.header {
  padding: 17px 20px;
  height: 100px;
  border-bottom: 1px solid #e1e3ef;
  position: relative;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hamburger {
      height: 30px;
      width: 30px;
      background-image: url('../../img/hamburger.svg');
      background-repeat: no-repeat;
      background-position: left center;
      cursor: pointer;
      flex-shrink: 0;
      display: none;

      @media (max-width: 850px) {
        display: block;
      }
    }

    .anyQuestions {
      font-size: 13px;
      color: #8c8f9e;

      .contact {
        text-decoration: underline;
        color: #545769;
      }
    }

    .langCurrencyAndProfile {
      display: flex;
      align-items: center;
      font-size: 15px;
      user-select: none;

      .languageAndCurrency {
        color: #8c8f9e;
        margin-right: 52px;
        cursor: pointer;
        position: relative;
        padding: 15px;
        height: 52px;
        display: flex;
        align-items: center;

        .currentLangAndCurWrapper {
          display: flex;

          .currentLangandCurTitle {
            @media (max-width: 450px) {
              display: none;
            }
          }

          .flag {
            margin-left: 10px;
            box-shadow: 0 0 1px #000;
            height: 14px;
            margin-top: 2px;
          }
        }

        .dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 120%;
          background-color: #fff;
          border-radius: 8px;
          border: 1px solid rgba(51, 51, 51, 0.363);
          padding: 15px;
          z-index: 100;
          font-size: 14px;

          @media (max-width: 450px) {
            width: 120px;
          }

          .item {
            position: relative;
            cursor: pointer;
            

            &:hover {
              color: #333;
            }

            + .item {
              margin-top: 10px;
            }

            .ddFlag {
              border: 0.5px solid rgba(0, 0, 0, 0.342);
              position: absolute;
              top: 0;
              right: 0;
            }
           .cFlag {
              border: 0.5px solid rgba(0, 0, 0, 0.342);
              position: absolute;
              top:-3px;
              right: 0;
            }
          }
        }
      }

      .profile {
        cursor: pointer;
        display: flex;
        align-items: center;

        @media (max-width: 550px) {
          font-size: 14px;
        }

        .avatar {
          margin-left: 15px;
          width: 66px;
          border-radius: 50%;
        }
      }
    }
  }
}
