.WidgetNotification {
    margin-bottom: 20px;
    padding: 12px 14px;
    border: 1px solid #ccccff;
    border-radius: 5px;
    background: #dce6ff;

    p {
        margin-top: 16px;
        white-space: pre-line;
    }

    a {
        color: #6470fe;

        &:hover {
            text-decoration: underline;
        }
    }
}