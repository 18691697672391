form.ask-name-form {
  width: 100%;

  input,
  button {
    width: 100%;
    margin-bottom: 7px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px 0;
    font-size: 1.1em;

    &:focus ~ label {
      opacity: 0.4;
    }
  }

  input[type='submit'],
  button {
    margin-top: 10px;
    height: 3em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    border: none;
    background-color: #347dfe;
    color: white;
    font-size: 0.9em;
    -webkit-appearance: none !important;
    cursor: pointer;
  }

  input:disabled {
    background-color: #b5b5b5;
  }
}

input[type='checkbox'] {
  appearance: auto !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
}
