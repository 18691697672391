@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('fonts/Inter-Thin.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('fonts/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('fonts/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('fonts/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('fonts/Inter-Black.ttf');
}



body {
  margin: 0;
  background-color: #f6f7fb;
  color: #0f1222;
  font-family: 'Inter', sans-serif;

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
