@import './variables.scss';
@import './mixins';

$container-destop: 1600px;
$container-laptop: 1090px;
$container-tablet: 698px;
$container-phone: 91%;

// *:focus{
//   outline: 2px solid limegreen;
// }

// html{
//   overflow: hidden;
// }
* {
  -webkit-appearance: none !important;
}

body {
  height: 100%;
}

.auth-container {
  padding: 0;
  margin: auto;
  @include respond-to(desktop) {
    width: $container-destop;
  }
  @include respond-to(laptop) {
    width: $container-laptop;
  }
  @include respond-to(tablet) {
    width: $container-tablet;
  }
  @include respond-to(phone) {
    width: $container-phone;
  }
}

@mixin placeholder() {
  color: $color-nonactive;
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  @include placeholder();
}
::-moz-placeholder {
  @include placeholder();
} /* Firefox 19+ */
:-moz-placeholder {
  @include placeholder();
} /* Firefox 18- */
:-ms-input-placeholder {
  @include placeholder();
}

.hidden {
  height: 0;
  width: 0;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinked {
  animation: blink 1.3s ease infinite;
}
