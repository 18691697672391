.SiteTransferNotification {
    margin-bottom: 20px;
    padding: 12px 14px;
    border: 1px solid #ccccff;
    border-radius: 5px;
    background: #dce6ff;
    white-space: pre-wrap;

    h2 {
        margin-bottom: 20px;
    }
}

.modal {
    max-width: 400px;
    min-width: 320px;
    margin: 50px 0;
    padding: 20px 30px 35px;
    background-color: #fff;
    color: #0f1222;
    border-radius: 10px;
    box-shadow: 0 19px 27px rgba(0, 0, 0, .3);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}