@import "../../scss/variables.scss";

.social-icon {
  position: relative;
  margin-right: 30px;
  cursor: pointer;
}

#whatsup,
#telegram,
#instagram {
  .svg-wrapper {
    &:hover,
    &.active {
      transition: all 0.2s;
      filter: drop-shadow(2px 5px 4px $green);
    }
    transition: all 0.2s;
  }
  svg {
    fill: whitesmoke;
  }
}

.social-links {
  background-color: $deep-blue;
  position: absolute;
  bottom: 70px;
  right: -58px;
  border-radius: 5px;
  padding: 10px;
  transition: opacity 0.2s, transform 0.2s, visibility 0s;

  &.closed {
    transform: scale(1.15);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: content;
    font-size: 1em;
    font-weight: normal;
    width: 150px;

    img {
      height: 30px;
      width: 40px;
      margin-right: 10px;
      flex-grow: 0;
    }

    img[alt="Support"] {
      transform: scale(0.9);
    }

    span {
      flex-grow: 2;
      word-wrap: none;
      min-width: 90px;
    }
  }

  .social-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.social-links::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid $deep-blue;
  top: 100%;
  right: 65px;
}

.whatsapp-icon {
  width: 30px;
  height: 30px;
}
.telegram-icon {
  width: 30px;
  height: 30px;
}
// }
