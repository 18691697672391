@import '../../scss/variables.scss';

// modal styles
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  .inner {
    max-width: 600px;
    min-width: 320px;
    margin: 50px 0;
    padding: 20px 30px 35px;
    background-color: #fff;
    color: #565b80;
    border-radius: 10px;
    box-shadow: 0 19px 27px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    p {
      width: 100%;
      margin-bottom: 20px;
    }

    button + button {
      margin-left: 20px;
    }
  }

  .card {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;

    flex-basis: 40%;
    flex-grow: 1;
    flex-shrink: 1;

    cursor: pointer;  
    transition: all .2s;

    &__group {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;
    }

    &__icon {
        display: inline-block;
        width: 50%;
        max-width: 100px;
        margin-bottom: 12px;
    }

    &_selected, &:hover {
        box-shadow: cornflowerblue 3px 3px 5px;
        transform: scale(1.1);

        transition: all .2s;
    }

    &:last-child {
      margin-right: 0;
    }

    input:not([type='submit']) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        cursor: pointer;
    }
  }

  // close button
  .modal_close {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: right;
    color: #8c8f9e;
    cursor: pointer;
    flex-grow: 1;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  // for money - amount of money
  .amount {
    font-weight: 600;
    margin-top: 0.5rem;
    flex-grow: 1;
    width: 100%;
  }

  // metainfo of modal
  .type {
    position: relative;
    font-size: 0.8em;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 2rem;
    opacity: 0.4;
  }

  .type:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #f6f7fb;
    margin-top: 2rem;
  }

  // header of modal
  h3 {
    /*operation detail*/
    margin: 1rem 0;
  }

  // table with content of modal
  .details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 12px;
  }

  // headers of fields
  .details_item:nth-child(odd) {
    color: #565b80;
    opacity: 0.4;
    min-width: 35%;
    margin-top: 10px;
  }

  // fields with data
  .details_item:nth-child(even) {
    color: #565b80;
    min-width: 35%;
    font-weight: 500;
    text-align: right;
    margin-top: 10px;
  }

  &_closed {
    display: none;
  }
}
