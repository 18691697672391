.inner {
  max-width: 400px;
  min-width: 320px;
  margin: 50px 0;
  padding: 20px 30px 35px;
  background-color: #fff;
  color: #0f1222;
  border-radius: 10px;
  box-shadow: 0 19px 27px rgba(0,0,0,.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  p {
    width: 100%;
    margin-bottom: 20px;
  }

  button + button {
    margin-left: 20px;
  }
}
