.globalNotification {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 280px;
  z-index: 1000;

  .notification {
    padding: 10px 22px 10px 65px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    line-height: 1.6;

    + .notification {
      margin-top: 10px;
    }

    &.error {
      background-color: #dd3939;
      padding-left: 22px;
      box-shadow: 0 19px 27px rgba(221, 57, 57, 0.35);
    }

    &.success {
      background-color: #3fce99;
      padding-left: 22px;
      box-shadow: 0 19px 27px rgba(57, 221, 160, 0.35);
    }
  }
}
