@import "../../scss/variables", "../../scss/common-styles", "../../scss/mixins";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap");
@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

$gradient: radial-gradient(
  circle farthest-corner at center,
  rgba($deep-blue, 0.4),
  rgba(#141625, 0.76)
);

.iti__flag {
  background-image: url("../../img/flags.png");
}
.iti.iti {
  color: black;
  display: flex;
  flex-grow: 2;
}

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {background-image: url("path/to/flags@2x.png");}
// }

.auth-wrapper {
  width: 100%;
  min-height: 600px;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  background-image: $gradient, url("../../img/background-1920.jpg");
  background-position: 25% 0;
  background-size: cover;
  background-repeat: no-repeat;
  color: $color-main;

  @include respond-to(laptop) {
    background-image: $gradient, url("../../img/background-1440.jpg");
  }
  @include respond-to(tablet) {
    background-image: $gradient, url("../../img/background-1024.jpg");
  }
  @include respond-to(phone) {
    background-image: $gradient, url("../../img/background-768.jpg");
  }
}

.auth-main {
  $radius: 5px;

  height: $main-height;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-content: center;

  a {
    color: $green;
    text-decoration: none;
  }

  #main__form {
    align-self: center;
    width: 330px;
    min-height: 100px;
    background-color: transparent;
    text-align: center;
    padding-top: 18px;
    @include respond-to(phone) {
      width: 100%;
      margin: 0;
    }

    // classes for handle animating
    &.fade-enter {
      opacity: 0;
      transform: translateX(-100%);
    }
    &.fade-enter-active {
      opacity: 1;
      transform: translateX(0%);
    }
    &.fade-exit {
      opacity: 1;
      transform: translateX(0%);
    }
    &.fade-exit-active {
      opacity: 0;
      transform: translateX(100%);
    }
    &.fade-enter-active,
    &.fade-exit-active {
      transition: opacity 500ms, transform 500ms;
    }
  }

  // agree with politic of confidential
  &__agree {
    margin: 24px auto 0;
    font-size: 13px;
    text-align: center;
    width: 70%;
    justify-self: center;

    input {
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      border: none;
    }

    .auth-main__checkbox:checked + label::before {
      background-color: $deep-blue;
      border-color: $color-nonactive;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10.621' height='7.479' viewBox='0 0 10.621 7.479'%3e%3cpath d='M-19172.217-4076.968s3.42,3.477,3.439,3.441,5.762-5.76,5.762-5.76' transform='translate(19172.93 4079.995)' fill='none' stroke='%23a1c229' stroke-width='2'/%3e%3c/svg%3e");
    }

    .auth-main__checkbox:focus + label::before {
      background-color: $deep-blue;
      box-shadow: 0 0 0 0.2rem $color-main;
      transition: all 0.1s;
    }

    label {
      user-select: none;
      position: relative;
      width: 70%;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 24px;
        left: -30px;
        margin-top: -20px;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #adb5bd;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        cursor: pointer;
      }
    }
  }

  &__avatar-big {
    width: 30%;
    justify-self: center;
    margin: auto;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  }

  // Capture of form
  &__capture {
    display: inline-block;
    text-align: left;
    width: 75%;
    font-size: 12px;
  }

  // contact card (invitor)
  &__contact {
    width: 77%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: auto;

    &-data {
      flex-grow: 1;
      padding: 8px 0;
    }

    img {
      height: 60px;
    }

    &:focus {
      outline: 2px solid white;
    }

    & + .main__continue {
      margin-top: 60px;
    }
  }

  // submit button
  &__continue {
    color: $color-main;
    background-color: $green;
    width: 77%;
    height: 2.7em;
    margin: 27px auto 0;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-border-radius: 5px;
  }

  // Ticket "forget password"
  &__forget {
    display: inline-block;
    text-align: right;
    width: 75%;
    font-size: 12px;
    white-space: pre-wrap;
    margin-top: 6px;
  }

  // header of all form
  &__header {
    font-size: 20px;
    margin: 0;
    padding: 0 30px;
  }

  // common styles for relation icon with input
  &__input {
    position: relative;
    display: flex;
    background-color: transparent !important;
    height: 40px;
    width: 295px;
    max-width: 85%;
    margin: 5px auto;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $input-gradient;
      box-sizing: border-box;
      min-width: 40px;
      height: 100%;
      fill: #33334a;
      margin-right: 2px;
      border-bottom-left-radius: $radius;
      border-top-left-radius: $radius;
      -webkit-appearance: none;
    }

    input[type="tel"],
    input[type="email"],
    input[type="text"],
    input[type="password"] {
      background-color: lighten($deep-blue, 5) !important;
      color: $color-main;
      box-sizing: border-box;
      height: 40px;
      padding-left: 10px;
      flex-grow: 1;
      border: none;
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;

      &:invalid:not(:placeholder-shown) {
        border: 1px solid orange;
      }

      &:valid {
        border: 1px solid green;
      }
    }

    input[type="tel"] {
      padding-left: 52px;
      // max-width: 100%;
    }
  }

  &__input-confirm {
    input[type="text"] {
      $height: 40px;
      $digits: 6;

      height: $height;
      width: $height * $digits + 5 * ($digits - 1);
      padding-left: 12px;
      background-color: transparent;
      background-image: url("../../img/input_confirm.png");
      font-family: "Roboto Mono", monospace;
      background-position: 50% 50%;
      background-size: cover;
      color: $color-main;
      box-sizing: border-box;
      border: none;
      flex-grow: 0;

      font-size: $height - 13px;
      letter-spacing: 28px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        border: 2px solid orange;
      }
    }
  }

  // name of invitor
  &__name {
    font-size: 18px;
    margin: 10px auto;
  }

  // nick of invitor
  &__nick {
    text-transform: uppercase;
    font-size: 12px;
    color: $color-nonactive;
    margin: 0;
  }

  // alternative links
  &__other {
    margin-top: 23px;
    font-size: 15px;
    text-align: center;
    color: $color-nonactive;

    a {
      color: $green;
    }
  }

  // subheader of form
  &__subheader {
    font-size: 14px;
    color: $color-main;
    margin: 10px 0;
    padding: 5px 60px 20px;
  }
}

.confirm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 88%;

  span {
    width: 22px;
  }
}

.disabled {
  color: $color-nonactive !important;
  cursor: auto;
}
