.loadableError{
  position:relative;
  top:50%;
  width: 100%;
  margin-top: -20vh;
  padding-top: 2em;
  text-align: center;
  font-size: 2em;
}
.loadableError__string{
  width: 100%;
}