@import "../../scss/variables", "../../scss/common-styles.scss";
@import "../../scss/mixins";

.auth-header {
  height: $header-height;
  max-height: 100px;
  background-color: transparent;

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__logo {
    margin: 5px;
    width: 30px;
    @include respond-to(phone){
      width: 50px;
    }
  }

  &__title {
    font-size: 12px;
    margin-left: 6px;
  }

  &__links {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
  }

  &__this-link {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    // border-bottom: 2px solid transparent;
    margin-right: 3em;
    margin-left: 1em;
    font-weight: bold;
    color: $color-main;
    text-decoration: none;

    &:visited {
      color: $color-main;
      text-decoration: none;
    }
  }

  &__lang {
    

    // &:before {
    //   content: attr(data-lang);
    //   position: absolute;
    //   top: -1px;
    //   left: -25px;
    //   color: $color-nonactive;
    // }
  }

  &__site-link {
    color: $color-nonactive;
  }
}
