.totalWidget {
  padding-bottom: 20px;
  max-width: 1200px;

  @media (max-width: 850px) {
    border-bottom: 1px solid #e1e3ef;
  }

  .mainTitle {
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 23px;
  }

  .widgetsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .widget {
    background-color: #fff;
    width: 24%;
    flex-shrink: 0;
    padding: 12px 14px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background-position: right center;
    background-repeat: no-repeat;
    border-radius: 5px;

    &.widget {
      @media (max-width: 550px) {
        padding: 0;
        padding-right: 3px;
        border: 0;
      }
    }

    &.shares {
      background-image: url('img/bg_shares.svg');
    }

    &.balance {
      background-image: url('img/bg_balance.svg');
    }

    &.partners {
      background-image: url('img/bg_partners.svg');
    }

    &.income {
      background-image: url('img/bg_income.svg');
    }

    .title {
      font-size: 12px;
      color: #999;
      flex-grow: 10;
      margin-right: 12px;
      order: 3;

      &.title {
        @media (max-width: 550px) {
          font-size: 2.7vw;
        }
      }
    }

    .quantity {
      font-weight: 700;
      font-size: 27px;
      width: 100%;
      order: 1;

      &.quantity {
        @media (max-width: 550px) {
          font-size: 3.1vw !important;
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }

      .growth {
        color: #3edda2;
        font-size: 13px;
        font-weight: 400;
        margin-left: 15px;
      }
    }

    .unit {
      font-size: 13px;
      margin-right: 12px;
      order: 2;

      &.unit {
        @media (max-width: 550px) {
          font-size: 2.6vw;
        }
      }
    }

    .more {
      font-size: 13px;
      cursor: pointer;
      background-image: url('../../img/ext_link.svg');
      background-position: right 5px;
      background-repeat: no-repeat;
      padding-right: 17px;
      order: 4;
    }
  }
}
