.button {
  height: 43px;
  line-height: 43px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #dce6ff;
  color: #545769;
  font-size: 17px;
  position: relative;
  cursor: pointer;
  user-select: none;
  text-align: center;
  display: table;
  
  &:hover {
    filter: brightness(.9);
    box-shadow: #347dfe 3px 3px 10px;
  }

  &.info {
    color: #fff;
    background-color: #347dfe;
  }

  &.success {
    color: #fff;
    background-color: #39dda0;

    &:hover {
      filter: brightness(.9);
      box-shadow: #39dda0 3px 3px 10px;
    }
  }

  &.danger {
    color: #fff;
    background-color: #ff3730;

    &:hover {
      filter: brightness(.9);
      box-shadow: #ff3730 3px 3px 10px;
    }
  }

  &.upload {
    color: #fff;
    background-color: #347dfe;
    font-size: 17px;
    padding: 0 22px 0 40px;
    background-image: url('img/plus.svg');
    background-position: 17px 50%;
    background-repeat: no-repeat;
  }

  &.disabled {
    pointer-events: none;
    background-color: #d6e1ff;
    cursor: default;
  }

  &.fullWidth {
    width: 100%;
  }

  &.textColorWhite {
    color: #fff;
  }

  &.btnArrowDown {
    padding-right: 34px;
  }

  .arrowDown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    width: 16px;
    height: 11px;
    margin-right: 8px;
    background-image: url('img/arrow_down.svg');
    background-repeat: no-repeat;
    background-position: center;

    &.opened {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
