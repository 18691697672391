// this mixn adds a layer of abstraction for media queries
@mixin respond-to($media) {
    @if $media == phone {
        @media only screen and (max-width: $small-screen - 1) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (min-width: $small-screen) and (max-width: $large-screen) {
            @content;
        }
    } @else if $media == laptop {
        @media only screen and (min-width: $large-screen + 1) and (max-width: $wide-screen) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (min-width: $wide-screen + 1) {
            @content;
        }
    }
}