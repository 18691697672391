@import "../../scss//variables.scss";

.linkMenu {
  background-color: $blue;
  position: absolute;
  bottom: 70px;
  border-radius: 5px;
  padding: 10px;
  transition: opacity 0.2s, transform 0.2s, visibility 0s;

  @media (max-width: 425px) {
    bottom: 130px;
  }

  &.closed {
    transform: scale(1.15);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
  }

  .linkMenu-link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: content;
    font-size: 1em;
    font-weight: normal;
    width: 150px;

    img {
      height: 30px;
      width: 40px;
      margin-right: 10px;
      flex-grow: 0;
    }

    img[alt="Support"] {
      transform: scale(0.9);
    }

    span {
      flex-grow: 2;
      word-wrap: none;
      min-width: 90px;
    }
  }

  .linkMenu-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.linkMenu::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid $blue;
  top: 100%;
  right: 110px;
}
