.inputGroup {
  .label {
    font-size: 13px;
    margin-bottom: 10px;
    display: block;
  }

  .input {
    height: 44px;
    line-height: 44px;
    border-radius: 3px;
    border: 1px solid #d7e2ff;
    padding: 0 13px;
    width: 290px;
    font-size: 13px;

    &.locked {
      background-color: #f6f7fb;
      border: 0;
      background-image: url('../../img/lock.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 12px) center;
    }

    &.fullWidth {
      width: 100%;
    }

    &.error {
      border-color: #f00;
    }
  }
}
