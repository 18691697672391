.transfersNotification {
  background-color: #fff;
  padding: 14px 14px 24px;
  max-width: 1200px;
  width: 100%;

  .transferWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    > * {
      margin-right: 20px;
    }

    > :last-child {
      margin-right: 0;
    }

    .transferId {
      line-height: 44px;
    }
  }
}
