.buyShares {
  /* Заголовок шага */
  .stepTitle {
    color: #545769;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }


  /* ШАГ ВЫБОРА ПАКЕТА */
  .stepPackage {
    padding-bottom: 20px;
    border-bottom: 1px solid #d7e2ff;
    margin-bottom: 35px;

    .desc {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 25px;
    }

    .packagesWrapper {
      display: flex;
      flex-wrap: wrap;
      user-select: none;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 10px;

      .package {
        width: 280px;
        /* height: 144px; */
        border-radius: 5px;
        background-color: #fff;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        padding: 20px;
        padding-left: 53px;
        position: relative;
        cursor: pointer;

        @media (only screen and (max-width: 600px)) {
          margin: 20px auto;
        }

        &:before {
          content: '';
          position: absolute;
          left: 14px;
          top: 13px;
          height: 10px;
          width: 26px;
          height: 26px;
          border: 1px solid #dce6ff;
          background-color: #f6f7fb;
          border-radius: 50%;
        }

        &.selected, &:active {
          box-shadow: 0 19px 27px rgba(52, 125, 254, 0.2);

          &:before {
            background-color: #347dfe;
          }
        }

        .title {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 7px;
        }

        .subtitle {
          color: #a7b8e3;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          transition: all .3s;
        }

        .subtitleGift {
          color: LimeGreen;
          transition: all .3s;
        }
      }
    }
  }


  /* ШАГ ОПЛАТЫ */
  .stepPayment {
    width: 100%;
    border-bottom: 1px solid #d7e2ff;
    padding-bottom: 10px;
    margin-bottom: 30px;

    .desc {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .descSub {
      color: #a7b8e3;
      font-size: 11px;
      margin-bottom: 25px;
    }

    .paymentSystemsWrapper {
      display: flex;
      flex-wrap: wrap;
      user-select: none;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 10px;

    }

    .separateBalance {
      display: block;

      .paymentSystem {
        cursor: default;
      }

      .withdrawAmountFromBalance {
        margin-left: 10px;
        margin-bottom: 20px;
        width: 280px;

        input {
          width: 100%;
          font-size: 14px;
        }
      }

      .plus {
        font-size: 50px;
        color: #ccc;
        text-align: center;
        width: 300px;
        margin-bottom: 20px;
      }
    }
  }

  .paymentSystemImgWrapper {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  } 

  .disabled:after {
    content: attr(data-message);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 20px;
    z-index: 1;
    background-color: #000000bb;
    border-radius: 5px;
    pointer-events: all;
    text-align: center;
    color: #dce6ff;
    font-size: 1.3em;
  }

  .paymentSystem img { 
    max-width: 100%;
    max-height: 100%;
  }


  /* Чекбокс */
  .checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;

    .fakeCheckbox {
      background-color: #f6f7fb;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: 2px solid #e4ebfd;
      display: inline-block;
      cursor: pointer;
      flex-shrink: 0;
    }

    input {
      display: none;

      &:checked ~ .fakeCheckbox {
        background-color: #347dfe;
      }
    }

    .text {
      margin-left: 10px;
      cursor: pointer;
    }
  }


  /* Кнопка оплаты */
  .btnPay {
    min-width: 280px;
  }
}  

@media (max-width: 600px) {
  .paymentSystem,
  .package {
    margin: 20px auto !important;
  }
  .scrollWrapper{
    display: flex;
    justify-content: center;
  }
  .separateBalance {
    display: flex;
    justify-content: center;
  }
}
