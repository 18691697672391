$root: '.Checkbox';

#{$root} {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;

    &-FakeCheckbox {
      background-color: #f6f7fb;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: 2px solid #e4ebfd;
      display: inline-block;
      cursor: pointer;
      flex-shrink: 0;
    }

    input {
      display: none;

      &:checked ~ #{$root}-FakeCheckbox {
        background-color: #347dfe;
      }
    }

    &-Text {
      margin-left: 10px;
      cursor: pointer;
    }
  }