.accountLocked {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .message {
    font-size: 24px;
    color: red;
    margin-bottom: 20px;
  }
}
