.preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(220, 230, 255, 0.5);

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  svg {
    animation-duration: 3s;
    animation-name: rotate;
    transition-timing-function: linear;
    animation-iteration-count: infinite;
    color: #7b9ef4;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
