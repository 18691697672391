.Card {
  display: block;
  position: relative;
  padding: 10px;
  margin: 10px 0;
  text-align: left;

  border-radius: 5px;
  background-color: #fff;

  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    box-shadow: 0 19px 27px rgb(52 125 254 / 20%);
  }

  &-Title {
    margin-bottom: 10px;
  }

  &-ActionButton {
    float: right;
  }

  &-Close {
    display: inline-block;
    position: absolute;

    top: 10px;
    right: 10px;

    font-size: 20px;
    line-height: 20px;

    &:hover {
        transform: scale(1.3);
        transition: all .2s;
    }
  }

  &-Addon {
    margin-top: 10px;
    font-weight: 300;

    &_isRed {
      color: red;
      font-weight: bold;
      transition: all .2s;
    }
  }

  &_closed {
      overflow: hidden;
        height: 0;
        padding: 0;
        transition: all .2s;
  }
}
