@import "../../scss/variables", "../../scss/mixins";

.auth-footer {
    display: flex;
    align-items: center;
    height: $footer-height;
    pointer-events: none;
    justify-content: space-between;

    @include respond-to(phone){
        flex-direction: column;
    }

    &__text {
        color: $color-nonactive;
        font-size: 0.7rem;
        width: 45%;
        margin-left: 30px;
        flex-grow: 1;
        
        @include respond-to(phone){
            width: 90%;
            margin-left: 0px;
        }
    }

    &__links {
        width: 150px;
        display: flex;
        justify-content: space-around;
        font-size: .9em;

        @include respond-to(phone){
            margin-left: 30px;
        }
    }

    &__link {
        width: 18px;
        margin: 0 5px;
        fill: #a1ccff;
    }

}
